import { Link, useLocation } from 'react-router-dom';
import ThirdPartyLogin from './ThirdPartyLogin';
import { useEffect, useRef, useState } from 'react';
import { RedirectLocationState } from '../routes/ProtectedRoute';
import { userLogin } from '../lib/api';
import { MfaMethod, MfaOrUserToken } from 'types';
import { anonymousLogin, passkeysSupported } from '../lib/passkey';

export interface MfaTokenSuccessReturn {
   allowMfaSetup: boolean;
   activeMethods: MfaMethod[];
}

export interface LoginSuccessProps {
   onPasswordTokenSuccess: (passwordToken: MfaOrUserToken) => void;
   onThirdPartyLoginSuccess: () => void;
}

interface LoginFormProps extends LoginSuccessProps {
   onPasskeySuccess: () => void;
}

export default function LoginForm({
   onPasswordTokenSuccess,
   onPasskeySuccess,
   onThirdPartyLoginSuccess,
}: LoginFormProps) {
   const location = useLocation<RedirectLocationState>();
   const urlParams = new URLSearchParams(location.search);
   const displayEmail = urlParams.get('u') || '';

   const [error, setError] = useState('');

   // Passkey login (from logged out, not as MFA)
   const abortControllerRef = useRef<AbortController>();
   useEffect(() => {
      if (!window.PublicKeyCredential?.isConditionalMediationAvailable())
         return;
      // If it gets cancelled, we want to offer it again, but only once, to avoid infinite loops
      let unloaded = false;
      const abortController = (abortControllerRef.current =
         new AbortController());
      anonymousLogin(abortController.signal).then(
         onPasskeySuccess,
         (e): any => {
            if (
               !unloaded &&
               !(e instanceof DOMException) &&
               e?.name !== 'NotAllowedError'
            ) {
               anonymousLogin(abortController.signal).then(onPasskeySuccess);
            }
         },
      );
      return () => {
         unloaded = true;
         abortController.abort();
      };
   }, []);

   const manualPasskey = () => {
      abortControllerRef.current?.abort();
      anonymousLogin(undefined, true).then(onPasskeySuccess, () =>
         setError('Error using passkey'),
      );
   };

   const [formValues, setFormValues] = useState({
      password: '',
      email: displayEmail,
   });

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.value,
      });
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
         const passwordToken: MfaOrUserToken = await userLogin(formValues);
         onPasswordTokenSuccess(passwordToken);
      } catch (err) {
         setError(
            err.message ??
               `The email and password you entered did not match our records.
         Please double-check and try again.`,
         );
      }
   };

   return (
      <>
         <h2 className="mb-6 text-left text-3xl font-extrabold text-gray-900">
            Sign in to your account
         </h2>

         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}

         <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
               <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
               >
                  Email address
               </label>
               <div className="mt-1">
                  <input
                     value={formValues.email}
                     autoFocus
                     onChange={handleChange}
                     id="email"
                     name="email"
                     type="email"
                     autoComplete="email username webauthn"
                     required
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>

            <div>
               <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
               >
                  Password
               </label>
               <div className="mt-1">
                  <input
                     value={formValues.password}
                     onChange={handleChange}
                     id="password"
                     name="password"
                     type="password"
                     autoComplete="current-password"
                     required
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                  />
               </div>
            </div>

            <div>
               <button
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
               >
                  Sign in
               </button>
            </div>
         </form>

         <Link
            className="my-4 block text-sm text-blue-400"
            to="/forgot-password"
         >
            Forgot Password
         </Link>

         {passkeysSupported ? (
            <div className="mt-2 mb-4">
               <p className="text-sm text-gray-500 border-t border-gray-300 pt-2 mt-2 mb-4">
                  If you have set up a passkey that allows passwordless login,
                  click here:
               </p>
               <div>
                  <button
                     type="button"
                     className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-orange-400 hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                     onClick={manualPasskey}
                  >
                     Use a Passkey
                  </button>
               </div>
            </div>
         ) : null}

         <ThirdPartyLogin
            onSuccess={onThirdPartyLoginSuccess}
            onError={setError}
         />
      </>
   );
}
