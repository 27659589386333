import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import Login from '../pages/Login';
import Contact from '../pages/Contact';
import InvestorDocuments from '../pages/InvestorDocuments';
import Profile from '../pages/Profile';
import Forbidden from '../pages/Forbidden';
import ResetPassword from '../pages/ResetPassword';
import ForgotPassword from '../pages/ForgotPassword';
import NotFound from '../pages/NotFound';
import Requests from '../pages/Request/Requests';
import Request from '../pages/Request';
import SurveyForm from '../components/SurveyForm';
import Forms from '../pages/Forms';
import { lazy, useContext, useEffect, useState } from 'react';
import Document from '../pages/Document';
import ProtectedRoute from './ProtectedRoute';
import Batches from '../pages/Batch/Batches';
import Batch from '../pages/Batch';

import { isUserToken, UserContext } from '../contexts/UserContext';
import IncorrectUser from '../components/IncorrectUser';
import { MsalContext, MsalProvider } from '@azure/msal-react';
import { msalAdminInstance, msalInvestorInstance } from '../authConfig';
import AdminLogin from '../pages/admin/AdminLogin';
import AdminRestart from '../pages/admin/AdminRestart';

const AdminRoutes = lazy(() => import('./AdminRoutes'));

const Routes = () => {
   const location = useLocation();
   const { user } = useContext(UserContext);
   const urlParams = new URLSearchParams(location?.search);
   const displayEmail = urlParams.get('u') || '';

   const [incorrectUser, setIncorrectUser] = useState<boolean>();

   const userEmail = isUserToken(user) && user.email;

   useEffect(() => {
      if (userEmail && displayEmail && displayEmail !== userEmail) {
         setIncorrectUser(true);
      }

      return () => setIncorrectUser(false);
   }, [displayEmail, userEmail]);

   return (
      <>
         {incorrectUser ? (
            <IncorrectUser
               loggedInEmail={userEmail || ''}
               attemptedEmail={displayEmail}
            />
         ) : (
            <Switch>
               {/* Admin app */}
               <Route path="/admin">
                  <MsalProvider instance={msalAdminInstance}>
                     <Switch>
                        <Route path="/admin/restart" component={AdminRestart} />
                        <Route path="/admin/login" component={AdminLogin} />
                        <ProtectedRoute
                           authLevel="admin"
                           component={AdminRoutes}
                        />
                     </Switch>
                  </MsalProvider>
               </Route>
               {/* User app */}
               <Route>
                  <MsalProvider instance={msalInvestorInstance}>
                     <Switch>
                        <ProtectedRoute
                           path="/form/:id"
                           authLevel="user"
                           component={SurveyForm}
                        />
                        <Route path="/login" component={Login} />
                        <ProtectedRoute
                           path="/"
                           exact
                           authLevel="user"
                           component={InvestorDocuments}
                        />
                        <ProtectedRoute
                           path="/contact"
                           authLevel="user"
                           component={Contact}
                        />
                        <ProtectedRoute
                           path="/profile"
                           authLevel="user"
                           component={Profile}
                        />
                        <ProtectedRoute
                           path="/requests/:id"
                           authLevel="fundManager"
                           component={Request}
                        />
                        <ProtectedRoute
                           path="/requests"
                           authLevel="fundManager"
                           component={Requests}
                        />
                        <ProtectedRoute
                           path="/files/:batchType/:batchId(\d+)"
                           authLevel="fundManager"
                           component={Batch}
                        />
                        <ProtectedRoute
                           path="/files"
                           authLevel="fundManager"
                           component={Batches}
                        />
                        <ProtectedRoute
                           path="/forms"
                           authLevel="user"
                           component={Forms}
                        />

                        <Redirect from="/Documents" to="/" />
                        <ProtectedRoute
                           path="/document/:id"
                           authLevel="user"
                           component={Document}
                        />
                        {/* <Route path="/mfa/activate" component={Activate} />
                        <Route path="/mfa/phone" component={Phone} />
                        <Route path="/mfa/verify" component={Verify} /> */}
                        <Route
                           path="/forgot-password"
                           component={ForgotPassword}
                        />
                        <Route path="/reset-password">
                           <Login flow="resetPassword" />
                        </Route>
                        <Route path="/forbidden" component={Forbidden} />

                        <Route path="/" component={NotFound} />
                     </Switch>
                  </MsalProvider>
               </Route>
            </Switch>
         )}
      </>
   );
};

export default Routes;
