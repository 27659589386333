import { useEffect, useState } from 'react';
import { mfaApiCall, mfaApiLogin } from '../../../lib/api';
import { MfaSetupMethodProps } from '../MfaSetupSingleMethod';
import Banner from '../../Banner';
import { MfaSetupAppResponse } from 'types/interface';
import SubmitButton from '../../SubmitButton';

export default function MfaSetupApp({ onSuccess }: MfaSetupMethodProps) {
   const authAppLinks = {
      microsoft: {
         ios: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
         android:
            'https://play.google.com/store/apps/details?id=com.azure.authenticator',
      },
      google: {
         ios: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
         android:
            'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
      },
      twilio: {
         ios: 'https://apps.apple.com/us/app/twilio-authy/id494168017',
         android:
            'https://play.google.com/store/apps/details?id=com.authy.authy',
      },
   };

   const [error, setError] = useState('');
   const [loading, setLoading] = useState(false);
   const [appInfo, setAppInfo] = useState<MfaSetupAppResponse>();
   const [formValues, setFormValues] = useState({
      codeToVerify: '',
      deviceName: '',
   });

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setFormValues({
         ...formValues,
         [e.target.name]: e.target.value,
      });
   };

   useEffect(() => {
      setupAppRequest();
   }, []);

   const setupAppRequest = () => {
      mfaApiCall<MfaSetupAppResponse>('post', `mfa/setup/app`)
         .then(res => setAppInfo(res))
         .catch(err => setError(err.message));
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!formValues.codeToVerify) {
         return setError('Please enter code');
      }

      setLoading(true);
      setError('');
      mfaApiLogin('post', 'mfa/app', {
         mfaAuthId: appInfo?.mfaAuthId,
         code: formValues.codeToVerify,
         deviceName: formValues.deviceName,
      })
         .then(() => onSuccess())
         .catch(err =>
            setError(
               err.message === 'Forbidden' || !err.message
                  ? `The code is incorrect`
                  : err.message,
            ),
         )
         .finally(() => setLoading(false));
   };

   return (
      <>
         {error ? (
            <div className="bg-red-200 p-4 rounded-md text-left my-2">
               {error}
            </div>
         ) : null}
         <p className="my-3">
            You'll need an authenticator app on your phone. Some examples are
         </p>
         <ul className="mx-6 list-disc list-inside">
            <li>
               Microsoft Authenticator (
               <a
                  href={authAppLinks.microsoft.ios}
                  target="_blank"
                  className="link"
               >
                  iOS
               </a>{' '}
               |
               <a
                  href={authAppLinks.microsoft.android}
                  target="_blank"
                  className="link"
               >
                  {' '}
                  Android
               </a>
               )
            </li>
            <li>
               Google Authenticator (
               <a
                  href={authAppLinks.google.ios}
                  target="_blank"
                  className="link"
               >
                  iOS
               </a>{' '}
               |
               <a
                  href={authAppLinks.google.android}
                  target="_blank"
                  className="link"
               >
                  {' '}
                  Android
               </a>
               )
            </li>
            <li>
               Twilio Authy Authenticator (
               <a
                  href={authAppLinks.twilio.ios}
                  target="_blank"
                  className="link"
               >
                  iOS
               </a>{' '}
               |
               <a
                  href={authAppLinks.twilio.android}
                  target="_blank"
                  className="link"
               >
                  {' '}
                  Android
               </a>
               )
            </li>
         </ul>

         <p className="my-3">
            Or any other app that supports the TOTP standard.
         </p>

         <p className="my-3">
            Once you have installed the app, scan this QR code with your phone
            to set it up:
         </p>

         <div className="flex justify-center">
            {appInfo?.qrCode ? <img src={appInfo?.qrCode} /> : <></>}
         </div>

         <p className="my-3">
            Or, if this is the device with the app installed,{' '}
            <a href={appInfo?.uri} target="_blank" className="link">
               click here
            </a>
            .
         </p>

         <p className="my-3">
            To complete the setup, enter the 6-digit code that the app
            generated:
         </p>
         <form className="space-y-6 group" onSubmit={handleSubmit}>
            <div className="mt-1">
               <input
                  value={formValues.codeToVerify}
                  onChange={handleChange}
                  id="codeToVerify"
                  name="codeToVerify"
                  type="text"
                  minLength={6}
                  maxLength={6}
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
            </div>

            <p className="my-3">
               Enter a friendly name to remind you which app it is:
            </p>
            <div className="mt-1">
               <input
                  value={formValues.deviceName}
                  onChange={handleChange}
                  id="deviceName"
                  name="deviceName"
                  type="text"
                  placeholder="E.g. Microsoft Authenticator on my iPhone"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
               />
            </div>
            <div>
               <SubmitButton
                  className="group-invalid:pointer-events-none group-invalid:opacity-30"
                  loading={loading}
               >
                  Complete Setup
               </SubmitButton>
            </div>
         </form>
      </>
   );
}
