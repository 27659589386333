import { MfaMethodTypes } from 'types';
import FooterMfaOptional from './FooterMfaOptional';
import { displayIcon, displayName } from './MfaMethodSelect';

interface Props {
   configuredMethods: MfaMethodTypes[];
   onSuccess: (mfaMethodType: MfaMethodTypes) => void;
   onSkip?: (() => void) | null;
}

export default function MfaMethodSetupSelect({
   configuredMethods,
   onSuccess,
   onSkip,
}: Props) {
   return (
      <>
         <h2 className="mb-6 text-left text-2xl font-extrabold text-gray-900">
            {onSkip ? (
               <>Would you like to set up multi-factor authentication?</>
            ) : (
               <>Multi-factor authentication</>
            )}
         </h2>
         <div className="p-4 bg-gray-100 rounded-md text-left my-6">
            {onSkip ? (
               <>
                  <p>
                     To keep your account secure, we recommend adding an
                     additional form of authentication to your login.
                  </p>
                  <p>
                     If you would like to set this up now, please select the
                     method you wish to use. You can add additional methods
                     later from your profile page.
                  </p>
               </>
            ) : (
               <>
                  <p>
                     To keep your account secure, we now require an additional
                     form of authentication when you login.
                  </p>
                  <p>
                     Please select a method and we will set it up for you. You
                     can add additional methods later from your profile page.
                  </p>
               </>
            )}
         </div>
         <div className="">
            {configuredMethods?.map(item => (
               <button
                  key={item}
                  type="button"
                  onClick={() => onSuccess(item)}
                  className="w-full flex py-4 px-4 my-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
               >
                  <span>{displayIcon[item]}</span>
                  <span className="pl-4">{displayName[item]}</span>
               </button>
            ))}
         </div>
         {onSkip && <FooterMfaOptional onSkip={onSkip} />}
      </>
   );
}
