import { MfaMethod, MfaMethodTypes } from 'types';
import MfaValidatePasskey from './Passkey/MfaValidatePasskey';
import MfaValidateApp from './App/MfaValidateApp';
import MfaEmailForm from './Email/MfaEmailForm';

export interface MfaValidateMethodProps {
   selectedMethodInfo: MfaMethod;
   onSuccess: () => void;
}
interface Props extends MfaValidateMethodProps {
   onCancel: (() => void) | null;
}

export default function MfaValidate(mfaValidateProps: Props) {
   const pages: Record<MfaMethodTypes, JSX.Element> = {
      email: <MfaEmailForm {...mfaValidateProps} />,
      app: <MfaValidateApp {...mfaValidateProps} />,
      passkey: <MfaValidatePasskey {...mfaValidateProps} />,
   };
   const { selectedMethodInfo, onCancel } = mfaValidateProps;
   return (
      <>
         {onCancel ? (
            <button
               type="button"
               className="inline-flex items-center border px-3 py-1.5 rounded-md text-blue-500 hover:bg-blue-50 mb-6"
               onClick={() => onCancel()}
            >
               <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="h-6 w-6"
               >
                  <path
                     strokeLinecap="round"
                     strokeLinejoin="round"
                     strokeWidth="2"
                     d="M7 16l-4-4m0 0l4-4m-4 4h18"
                  ></path>
               </svg>
               <span className="ml-1 font-bold">Back</span>
            </button>
         ) : (
            <></>
         )}
         {pages[selectedMethodInfo.methodType]}
      </>
   );
}
